<template>
  <div class="bg-white p-4 h-full text-black">
    <h2 class="font-bold fs-24 mb-4">{{ $t("Danh sách Dịch vụ") }}</h2>

    <div class="flex flex-col md:flex-row gap-3 items-end">
      <div class="flex-1 flex gap-3 items-center">
        <div>
          <span class="mr-3">Tìm kiếm</span>
          <el-input
            class="cs-input"
            placeholder="Tìm kiếm theo tên,..."
            v-model="searchValue"
            clearable
            @input="handleDebounce"
          >
            <el-button slot="prepend" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <div>
          <div class="mr-3">Danh mục</div>
          <el-select
            v-model="params.services_category_id"
            placeholder="Tất cả"
            filterable
            @change="getListServiceCatalog"
            clearable
          >
            <el-option
              v-for="item in serviceCates"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- <div>
        <span class="mr-3">Đơn vị cung cấp</span>
        <el-select
          v-model="params.services_provider_id"
          placeholder="Tất cả"
          filterable
          @change="getListServiceCatalog"
          clearable
        >
          <el-option
            v-for="item in serviceProviders"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>-->
      <div>
        <el-button
          @click="handleOpenModalSyncService"
          icon="el-icon-refresh"
          type="primary"
        >
          {{ $t("Đồng bộ dịch vụ") }}
        </el-button>
        <el-button @click="handleCreate" icon="el-icon-plus" type="primary">
          {{ $t("Thêm mới") }}
        </el-button>
      </div>
    </div>
    <div class="flex justify-end gap-3 mt-2">
      <!-- <el-button @click="handleClone" type="primary" class="ml-0">{{ $t("Chọn DV") }}</el-button> -->
    </div>

    <div class="mt-4">
      <el-table
        :data="serviceCatalog"
        style="width: 100%"
        row-class-name="text-black text-sm"
        header-cell-class-name="text-black text-base font-semibold"
        class="cursor-pointer"
        @row-click="goToViewDetail"
      >
        <el-table-column
          width="300"
          prop="name"
          label="Tên Dịch vụ"
        ></el-table-column>
        <el-table-column
          width="180"
          prop="services_code"
          label="Mã Dịch vụ"
        ></el-table-column>
        <el-table-column
          prop="service_category.name"
          label="Danh mục"
        ></el-table-column>
        <el-table-column
          prop="service_provider.name"
          label="Đơn vị cung cấp"
        ></el-table-column>
        <el-table-column
          class="text-right"
          :formatter="
            (row) => {
              return row.price ? appUtils.numberFormat(row.price, 'VND') : '';
            }
          "
          label="Giá dịch vụ"
          align="right"
        ></el-table-column>
      </el-table>
      <div class="flex justify-content-end mt-3">
        <AppPagination
          :paginationProps="pagination"
          @onChangePagination="getListServiceCatalog"
        />
      </div>
    </div>
    <ModalSyncServiceLocal ref="ModalSyncServiceLocal" />
  </div>
</template>
  
  <script>
import { AppPagination } from '../../components/Common'
import appUtils from '../../utils/appUtils'
import { debounce } from 'lodash'
import { SERVICE_GROUP } from '@/utils/constants'
import ModalSyncServiceLocal from './ModalSyncServiceLocal.vue'

export default {
  name: 'ServiceCatalog',
  components: { AppPagination, ModalSyncServiceLocal },
  data () {
    return {
      pagination: {
        currentPage: 1,
        pageSize: 15,
        totalItems: 0
      },
      params: {
        services_provider_id: '',
        services_category_id: ''
      },
      searchValue: '',
      serviceCatalog: [],
      serviceProviders: [],
      appUtils,
      SERVICE_GROUP,
      serviceCates: []
    }
  },
  created () {
    this.getListServiceCatalog()
    this.getListServiceProvider()
    this.getListServiceCategory()
  },
  methods: {
    async getListServiceCatalog (data) {
      if (!this.$globalOrg?.id) return
      try {
        const params = {
          org_id: this.$globalOrg?.id,
          // ws: this.$globalClinic?.id,
          page_num: data?.currentPage || this.pagination.currentPage,
          page_size: data?.pageSize || this.pagination.pageSize,
          sort_by: 'created_at',
          order: 'desc'
        }
        if (this.searchValue) {
          params.keyword = this.searchValue
        }
        if (this.params.services_provider_id) {
          params.provider = this.params.services_provider_id
        }
        if (this.params.services_category_id) {
          params.cate = this.params.services_category_id
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getIndicationService(params)

        const responseData = response.data || {}
        this.serviceCatalog = responseData?.data || []
        this.pagination = {
          currentPage:
            responseData?.page?.page_num || this.pagination.currentPage,
          pageSize: responseData?.page?.page_size || this.pagination.pageSize,
          totalItems: responseData?.page?.total || 0
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getListServiceCategory () {
      if (!this.$globalOrg?.id) return
      try {
        const params = {
          org_id: this.$globalOrg?.id,
          page_num: 1,
          page_size: 1000,
          sort_by: 'created_at',
          order: 'desc'
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getListServiceCategory(params)
        const responseData = response.data || {}
        this.serviceCates = responseData?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    async getListServiceProvider () {
      if (!this.$globalOrg?.id) return
      try {
        const params = {
          // org_id: this.$globalOrg?.id,
          page_num: 1,
          page_size: 1000,
          sort_by: 'created_at',
          order: 'desc'
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getListServiceProvider(params)
        const responseData = response.data || {}
        this.serviceProviders = responseData?.data || []
        console.log(response)
      } catch (error) {
        console.log(error)
      }
    },
    goToViewDetail (row) {
      //   console.log(row)
      // this.$refs.ModalCreateServiceCategory.handleOpen(true, row)
      this.$router.push({
        name: 'EditServiceCatalog',
        params: { id: row.id }
      })
    },
    handleCreate () {
      this.$router.push({
        name: 'CreateServiceCatalog',
        params: { id: '' }
      })
    },
    handleClone () {
      this.$router.push({
        name: 'CreateServiceCatalog',
        params: { id: '' },
        query: { type: 'public' }
      })
    },
    handleDebounce: debounce(function () {
      this.getListServiceCatalog()
    }, 500),
    handleOpenModalSyncService () {
      this.$refs.ModalSyncServiceLocal.handleOpen()
    }
  }
}
</script>
  
  <style lang="scss" scoped>
::v-deep {
  .cs-input {
    .el-input__inner {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border: 1px solid #dcdfe6 !important;
      background-color: white !important;
    }

    .el-input-group__append {
      background-color: white !important;
    }
  }

  .cs-disabled {
    .el-input__inner {
      background-color: rgba(228, 228, 228, 0.823) !important;
      color: #373737 !important;
    }

    .el-input-group__append {
      background-color: rgba(228, 228, 228, 0.823) !important;
    }
  }
}
</style>