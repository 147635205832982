<template>
  <div>
    <el-dialog
      :visible.sync="isOpen"
      width="50%"
      center
      :before-close="handleClose"
    >
      <template slot="title">
        <div class="fs-20 font-bold uppercase">
          {{ $t("Đồng bộ dịch vụ") }}
        </div>
      </template>
      <div class="flex flex-col gap-2">
        <label class="required">{{ $t("Khoa/Phòng ban muốn đồng bộ") }}</label>
        <el-select
          v-model="selectedWorkspaces"
          multiple
          placeholder="Chọn Khoa/Phòng bạn muốn đồng bộ"
        >
          <el-option
            v-for="item in workspaces"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div
          class="flex flex-col md:flex-row items-center gap-2 justify-end mt-4"
        >
          <el-button plain type="primary" @click="handleClose">{{
            $t("Huỷ")
          }}</el-button>
          <el-button
            :disabled="isLoading"
            :icon="isLoading ? 'el-icon-loading' : ''"
            type="primary"
            @click="handleSubmit"
            >{{ $t("Đồng bộ") }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ModalSyncServiceLocal',
  data () {
    return {
      isOpen: false,
      workspaces: [],
      selectedWorkspaces: [],
      isLoading: false
    }
  },
  methods: {
    handleOpen () {
      this.isOpen = true
      this.getWorkspaces()
    },
    handleClose () {
      this.isOpen = false
    },
    async handleSubmit () {
      if (this.isLoading || !this.$globalOrg?.id) return

      if (!this.selectedWorkspaces?.length) {
        this.$toast.open({
          message: this.$t('Vui lòng chọn khoa/phòng ban bạn muốn đồng bộ'),
          type: 'error'
        })

        return
      }

      try {
        this.isLoading = true
        const params = {
          org_id: this.$globalOrg.id,
          worksapce_ids: this.selectedWorkspaces
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .syncServiceLocal(params)

        if (response.status === 200) {
          this.$toast.open({
            message: this.$t('Đồng bộ thành công'),
            type: 'success'
          })
        }

        this.handleClose()
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async getWorkspaces () {
      if (!this.$globalOrg?.id) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getWorkSpacesAllV2(this.$globalOrg?.id)

        this.workspaces = response.data || []
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>